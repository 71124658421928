<template>
	<div class="personal">
		<div class="box-top"></div>
		<div class="top-hurdle">
			<img src="../assets/mask/logo.png" style="width: 95px; height: 30px;margin-top: 10px;margin-left: 20px;" />
			<div class="left-text c-p" @click="handleSkip(1)">
				合规体检
			</div>
			<div class="right-text c-p" @click="handleSkip(2)">
				公司介绍
			</div>
			<div class="exit">
				<el-button type="text" @click="handleExit">退出登录<i class="el-icon-arrow-right el-icon--right"></i></el-button>
			</div>
		</div>
		<div class="box-center">
			<div class="box-logo">
        <el-image 
          fit="cover"
          :src="detailInfo.companyLogo || require('../assets/mask/login/logo.png')" 
          style="width: 110px; height: 110px;border-radius: 50%;margin-right: 20px"></el-image>
				<div class="logo-text">
          <div>
            <span class="title">会员{{detailInfo.mobile}}</span>	
            <!-- <img src="../assets/mask/bj.png" 
              style="width: 18px; height: 18px"
              @click="handleEdit"/> -->
          </div>
          <div style="font-size: 14px; color: #DAB87A;">VIP会员：{{detailInfo.expireDate}}到期</div>
				</div>
			</div>
			<div class="content">
				<div style="text-align: center;color: #1577CB;">
					VIP会员介绍
					<div class="box-title"></div>
				</div>
				<div class="flrmin-text">
          1. 会员有效期内，可无限使用Mask体检器。<br /><br />
          2. 会员可在Mask平台设置自己企业信息。<br /><br />
          3. Mask刑事行政合规体检为VIP会员提供最  便捷的法律体检工具。<br />
				</div>
				<div style="padding-top: 35px;">
					<el-button type="warning" @click="handleCriterion">VIP会员续费</el-button>
				</div>
			</div>
      <div>
        <el-button 
          style="float: right;color: #505050" 
          type="text"
          @click="handleEdit"
        >修改密码 >
        </el-button>
      </div>
		</div>
    <div class="floor">
      Copyright ©2022-2025  Mask版权所有
    </div>
		<el-dialog
      title="修改密码"
      :visible.sync="dialogAlter"
      width="480px"
      :before-close="handleClose">
      <el-form ref="formEdit" :model="formEdit" class="formEdit" label-width="90px">
        <el-form-item label="旧密码" prop="password">
          <el-input 
            v-model="formEdit.password" 
            placeholder="请输入旧密码" 
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input 
            show-password
						v-model="formEdit.newPassword" 
						placeholder="请输入新密码"
					></el-input>
        </el-form-item>
        <el-form-item label="确定新密码">
          <el-input 
            show-password
						v-model="formEdit.introduce" 
						placeholder="再次请输入新密码"
					></el-input>
        </el-form-item>
      </el-form>
      
      <div slot="footer" class="retrieve-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSave('formEdit')">保存</el-button>
      </div>
		</el-dialog>
    <el-dialog
      :visible.sync="dialogVisible"
      width="480px"
      :before-close="handleCancel">
			<div class="formInfo">
				<div>
					请用微信扫码支付会员年费
				</div>
				<div class="content-coder">
					<vue-qr :text="QRcode" :size="200"></vue-qr>
				</div>
				<div class="bottom">
					需支付VIP会员年费：￥{{totalAmount}}/年 
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getPersonagedetail, payment, changePassword, getMemberorderDetail } from '../api'; 
import vueQr from 'vue-qr'
	export default {
    name: 'personal',
    components: {
      vueQr
    },
    data() {
			return {
				dialogAlter: false,
				dialogVisible: false,
				detailInfo: {},
				formEdit: {
          password: '',
          newPassword: '',
        },
        newPasswordTwo: '',
        Rules: {
          password: [
            {required: true, message: "请输入旧密码", trigger: "blur"}
          ],
          newPassword: [
            {required: true, message: "请输入新密码", trigger: "blur"}
          ],
        },
        totalAmount: '',
        QRcode: '',
        orderId: '',
        orderTime: null,
			}
		},
    created() {
      this.userId = this.userInfo.user_id;
      this.initData();
    },
    computed: {
      ...mapGetters(["userInfo"])
    },
		methods: {
      ...mapActions(['handleLogOut']),
      initData() {
         getPersonagedetail({ id: this.userId }).then(({ data }) => {
           this.detailInfo = data;
           this.detailInfo.mobile = data.mobile.substr(0,3) + '****' + data.mobile.substr(7,4);
        });
      },
      handleClose() {
        this.dialogAlter = false;
			},
			handleCriterion() {
				payment({ qty: 1 }).then(({data})=>{
          this.QRcode = data.paySign.codeUrl;
          this.totalAmount = data.totalAmount;
          this.dialogVisible = true;
          this.handleInquire(data.orderId);
        });
			},
      handleInquire(orderId) {
        const id = orderId;
        this.orderTime = setInterval(() => {
          getMemberorderDetail({id}).then((res) => {
            if(res.data.isPay === 1){
              this.dialogVisible = false;
              this.initData();
              clearInterval(this.orderTime);
              this.$message({
                message: '续费成功',
                type: 'success'
              });
            }
          });
        },3000);
      },
      handleCancel() {
        this.$message.error('续费失败');
        clearInterval(this.orderTime);
        this.dialogVisible = false;
      },
			handleEdit() {
				this.dialogAlter = true;
			},
			handleSave(formName) {
				this.$refs[formName].validate(valid => {
          if (valid) {
            const loading = this.$loading({
              lock: true,
              text: "修改中...",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)",
            });
            const password = this.$sha1(this.$md5(this.formEdit.password));
            const newPassword = this.$sha1(this.$md5(this.formEdit.newPassword));
            changePassword({
              password,
              newPassword
            }).then(()=> {
              this.$message({
                  type: "success",
                  message: "修改成功，请重新登录"
              });
              this.handleExit();
              this.formEdit = {
                password: '',
                newPassword: '',
              }
            })
            .finally(() => {
              this.dialogVisible = false;
              loading.close();
            });
          } else {
            return ;
          }
        });
			},
			handleSkip(type) {
        if(type === 1) {
          this.$router.push({path:'/criterion'});
        }else {
          this.$router.push({path:'/firminfo'});
        }
			},
			handleExit() {
        this.handleLogOut();
        this.$router.replace({
          path: "/login",
        });
			},
		}
	}
</script>

<style lang="less">
	.personal{
		.box-top{
			width: 100%;
			height: 440px;
			background: url('../assets/mask/bg.png') no-repeat;
			background-size: 100% 100%;
			position: absolute;
			z-index: -1;
		}
		.top-hurdle{
			width: 100%;
			height: 50px;
			background: #444A59;
			display: flex;
			font-size: 15px;
			color: #cccacc;
			line-height: 50px;
			
			.left-text{
				margin: 0 20px;
			}
			.exit{
				.el-button--text{
					color: #cccacc;
				}
				position: absolute;
				right: 5px;
			}
			
		}
		.box-center{
			width: 430px;
			margin: 0 auto;
			
			.box-logo{
				padding-top: 60px;
				padding-bottom: 35px;
				display: flex;
			}
			.logo-text{
				padding-top: 39px;
				color: #fff;
        .title {
          font-size: 18px;
          margin-right: 21px;
        }
			}
		}
		.content{
      width: 332px;
			background-color: #fff;
			border-radius: 5px;
			padding: 15px 49px;
			
			.box-title{
				height: 3px;
				width: 30px;
				background: #DAB87A;
				margin: 0 auto;
				margin-top: 8px;
				margin-bottom: 30px;
			}
			
			.flrmin-text{
        font-size: 14px;
			}
			
			.el-button--warning:focus, 
			.el-button--warning:hover,
			.el-button--warning {
        background-color: #DAB87A;
				border-color: #DAB87A;
				width: 100%;
				margin-bottom: 25px;
				font-size: 18px;
			}
		}

    .floor{
      width: 100%;
      font-size: 14px;
      color: #C8C8C8;
      position: absolute;
      text-align: center;
      bottom: 10px;
      z-index: -1;
    }
		
		.el-dialog__footer{
			padding: 0;
			text-align: left;
		}
		.el-dialog__body{
			padding: 0 20px 10px 20px;
		}
		.retrieve-footer{
			.el-button{
				width: 240px;
				border-radius: 0;
			}
			.el-button+.el-button{
				margin-left: 0;
			}
			.el-button--default{
				background: #DAB87A;
				border: 1px solid #DAB87A;
				color: #fff;
			}
			.el-button--primary{
				color: #FFF;
				background-color: #1577CB;
				border-color: #1577CB;
			}
		}
		.formEdit{
			.el-upload--picture-card{
				width: 100px;
				height: 100px;
				line-height: 100px;
			}
		}
    .formInfo{
			width: 280px;
			margin: 10px auto;
			font-size: 18px;
			text-align: center;
			.content-coder {
				width: 200px;
				height: 200px;
				margin: 20px auto;
			}
			.bottom {
				color: #DAB87A;
			}
		}

	}
</style>

